import Vue from 'vue'
import VueRouter from 'vue-router'
import Profile from '../views/FrontendPages/Profile.vue'

/* Layouts */
const FrontendLayout = () => import('../layouts/FrontendLayout')
const SubscribeLayout = () => import('../layouts/SubscribeLayoutKorek.vue')
const SubscribeLayoutEtsalat = () =>
  import('../layouts/SubscribeLayoutEtsalat.vue')

const LandingPage = () => import('../views/FrontendPages/MainPage.vue')
const MovieCatgeory = () => import('../views/FrontendPages/MovieCategory')
const MyVideos = () => import('../views/FrontendPages/MyVideos')
const MovieDetail = () => import('../views/FrontendPages/MovieDetail')
const ThankYou = () => import('../views/FrontendPages/ThankYouPage.vue')

/* subscribe pages */
const Login = () => import('../views/SubscribePages/login.vue')
const Verify = () => import('../views/SubscribePages/verify.vue')
const LoginEtsalat = () => import('../views/EtsalatSubscribePages/login.vue')
const VerifyEtsalat = () => import('../views/EtsalatSubscribePages/verify.vue')
const TermsAndConditions = () =>
  import('../views/EtsalatSubscribePages/TermsAndConditions.vue')
const PrivacyAndPolicy = () =>
  import('../views/EtsalatSubscribePages/PrivacyAndPolicy.vue')

const MsisdnLogin = () =>
  import('../views/EtsalatSubscribePages/MsisdnLogin.vue')

const RedirectPage = () => import('../views/SubscribePages/redirect.vue')

Vue.use(VueRouter)

const landingPageRoutes = (prop) => [
  {
    path: '/thank-you',
    name: prop + '.movie-detail',
    meta: { auth: true, name: 'thank-you', slider: 'false' },
    component: ThankYou
  },
  {
    path: '/',
    name: prop + '.landing-page',
    meta: { auth: true, name: 'landing page 1', slider: 'true' },
    component: LandingPage
  },
  {
    path: '/category/:category',
    name: prop + '.category',
    meta: { auth: true, name: 'Movie Category', slider: 'false' },
    component: MovieCatgeory
  },
  {
    path: '/movie-detail/:contentID',
    name: prop + '.movie-detail',
    meta: { auth: true, name: 'Movie Detail', slider: 'false' },
    component: MovieDetail
  },
  {
    path: '/myVideos',
    name: prop + '.myVideos',
    meta: { auth: true, name: 'MyVideos', slider: 'false' },
    component: MyVideos
  },
  {
    path: '/profile',
    meta: { auth: true, name: 'profile', slider: 'false' },
    component: Profile
  }
]

const subscribeRoutes = (prop) => [
  {
    path: '/',
    name: '/login page',
    meta: { auth: false, name: 'login', slider: 'true' },
    component: Login
  },
  {
    path: '/verify/:phoneNumber?/:referenceId?',
    name: '/verify page',
    meta: { auth: false, name: 'verify', slider: 'true' },
    component: Verify
  }
]
const subscribeRoutesEtsalat = () => [
  {
    path: '/',
    name: '/login etsalat page',
    meta: { auth: false, name: 'login-etsalat', slider: 'true' },
    component: LoginEtsalat
  },
  // login with phone number
  {
    path: '/msisdn-login',
    name: 'msisdn-login',
    component: MsisdnLogin,
    meta: { auth: false }
  },

  {
    path: '/verify-etsalat/:phoneNumber?',
    name: '/verify etsalat page',
    meta: { auth: false, name: 'verify-etsalat', slider: 'true' },
    component: VerifyEtsalat
  }
]

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: SubscribeLayout,
    meta: { auth: false },
    children: subscribeRoutes()
  },
  {
    path: '/login-etisalat',
    name: 'Login-etisalat',
    component: SubscribeLayoutEtsalat,
    meta: { auth: false },
    children: subscribeRoutesEtsalat()
  },
  // login with phone number
  {
    path: '/msisdn-login',
    name: 'msisdn-login',
    component: MsisdnLogin,
    meta: { auth: false }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-conditions',
    component: TermsAndConditions,
    meta: { auth: false }
  },
  {
    path: '/privacy-and-policy',
    name: 'privacy-policy',
    component: PrivacyAndPolicy,
    meta: { auth: false }
  },
  {
    path: '/',
    name: 'landing-page',
    component: FrontendLayout,
    meta: { auth: true },
    children: landingPageRoutes('landing-page')
  },
  {
    path: '/redirect',
    name: 'redirect-page',
    component: RedirectPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (!localStorage.getItem('highFitToken')) {
      const loginPagePath =
        localStorage.getItem('countryCode') === '964'
          ? '/login'
          : '/login-etisalat'
      next(loginPagePath)
    } else {
      next()
    }
  } else {
    next()
  }
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

export default router
