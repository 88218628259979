import axios from 'axios'
// import { router } from '../router'
import AlertServie from './errors'

const ApiService = {
  baseUrl: 'https://high-fit.net:3459/api/v1',
  korekBaseUrl: 'https://korek-he.trendy-technologies.com',
  headers: {
    // Authorization: 'Bearer ' + localStorage.getItem('highFitToken'),
    lang: localStorage.getItem('lang') ?? 'en'
  },
  init () {
    localStorage.setItem('lang', localStorage.getItem('lang') ?? 'en')

    axios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem('highFitToken')
        if (!config.url.includes(this.korekBaseUrl)) {
          config.headers.Authorization = `Bearer ${accessToken}`
        }

        return config
      },
      (error) => {
        console.error(error)
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response ? error.response.status : null

        // if (status === 401) {
        //   localStorage.removeItem('loggedIn')
        //   const loginPagePath =
        //     localStorage.getItem('countryCode') === '964'
        //       ? '/login'
        //       : '/login-etisalat'
        //   router.push(loginPagePath)
        // } else

        if (status === 422) {
          error.response.data.errors.map(function (value, key) {
            AlertServie.errorMessage(error.response.data.errors[key][0])
          })
        } else if (status === 402) {
          AlertServie.errorMessage(error.response.data.msg)
        } else if (status === 400) {
          AlertServie.errorMessage(error.response.data.message)
        }
        // else {
        //   AlertServie.errorMessage('Error')
        // }

        return Promise.reject(error)
      }
    )
  },
  changeLAng () {
    this.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('highFitToken'),
      lang: localStorage.getItem('lang') ?? 'en'
    }
  },
  async generateScript (uuid, timestamp) {
    delete axios.defaults.headers.common.lang
    try {
      const response = await axios.get(
        this.korekBaseUrl +
          '/dcbprotect.php?action=script&ti=' +
          uuid +
          '&ts=' +
          timestamp +
          '&te=%23sub_btn&servicename=highFit&merchantname=Tornet'
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async login (phoneNumber, ti) {
    axios.defaults.headers.common['lang'] = localStorage.getItem('lang') ?? 'en'
    const path =
      localStorage.getItem('countryCode') === '964'
        ? '/auth/login'
        : '/auth/etisalat/subscribe'
    const phoneNumberKey =
      localStorage.getItem('countryCode') === '971' ? 'msisdn' : 'phoneNumber'
    // eslint-disable-next-line camelcase
    const tracking_id = localStorage.getItem('tracking_id')
    try {
      const response = await axios.post(this.baseUrl + path, {
        [phoneNumberKey]: phoneNumber,
        ti: ti,
        tracking_id
      })
      return response
    } catch (e) {
      return e.response
    }
  },

  async verify (phoneNumber, otp, ts, ti, referenceId) {
    const isIraqi = localStorage.getItem('countryCode') === '964'
    const path = isIraqi ? '/auth/verify' : '/auth/etisalat/verify'
    // eslint-disable-next-line camelcase
    const tracking_id = localStorage.getItem('tracking_id')

    try {
      const phoneNumberKey = isIraqi ? 'phoneNumber' : 'msisdn'
      const payload = {
        [phoneNumberKey]: phoneNumber,
        pincode: otp,
        ts: ts,
        ti: ti,
        tracking_id
      }
      if (!isIraqi) {
        payload.referenceId = referenceId
      }

      const response = await axios.post(this.baseUrl + path, payload)
      return response
    } catch (e) {
      return e.response
    }
  },

  async checkUserStatus (phoneNumber) {
    try {
      const response = await axios.post(
        this.baseUrl + '/auth/check-user-status',
        {
          phoneNumber: phoneNumber
        }
      )
      return response
    } catch (e) {
      return e.response
    }
  },
  async refreshToken (id) {
    return axios
      .get(`https://high-fit.net:3459/api/v1/auth/etisalat/refreshToken/${id}`)
      .then((response) => {
        if (response.data.data && response.data.code === 22) {
          if (response.data.data !== '' && response.data.data.access_token) {
            localStorage.setItem(
              'highFitToken',
              response.data.data.access_token
            )
            this.headers = {
              Authorization: 'Bearer ' + response.data.data.access_token,
              lang: localStorage.getItem('lang')
            }
            window.location.href = '/'
          }
        }
      })
  },
  async getSetting () {
    try {
      const response = await axios.get(this.baseUrl + '/site/getData')
      return response
    } catch (e) {
      return e.response
    }
  },

  async getCategories () {
    try {
      const response = await axios.get(this.baseUrl + '/category/list', {
        headers: this.headers
      })
      return response
    } catch (e) {
      return e.response
    }
  },

  async getLastContentCategories () {
    try {
      const response = await axios.get(
        this.baseUrl + '/content/content-per-category',
        { headers: this.headers }
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async getContentByCategory (category) {
    try {
      const response = await axios.get(
        this.baseUrl + '/content/list-by-category?category_id=' + category,
        { headers: this.headers }
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async openContent (contentID) {
    try {
      const headersToBeSent = {
        'Country-Code': localStorage.getItem('countryCode'),
        ...this.headers
      }
      const response = await axios.get(
        this.baseUrl + '/content/openit/' + contentID,
        { headers: headersToBeSent }
      )
      return response
    } catch (e) {
      return e.response
    }
  },

  async myContents () {
    try {
      const response = await axios.get(
        this.baseUrl + '/content/subscriber-contents',
        { headers: this.headers }
      )
      return response
    } catch (e) {
      return e.response
    }
  }
}

export default ApiService
